


export default [
    {
        icon:require("@/views/Message/Svg/image.svg"),
        title:"图片",
        state:"select-img"
    }
]
