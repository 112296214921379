import {
  Module,
  VuexModule,
  getModule,
  Mutation,
  Action
} from "vuex-module-decorators";
import store from "@/store/index";
import ChatStoreType from "@/views/Message/Chat/Store/indexType";
import {ChatListType, GroupChatHistoryListType,} from '@/Api/Message/indexType';


@Module({
    name: "Chat",
    store,
    dynamic: true
})
export default class Chat extends VuexModule implements ChatStoreType {

    // 查询群聊
    GroupChatUpData: GroupChatHistoryListType|any = {
        // eslint-disable-next-line
        // @ts-ignore
        groupId: "", pageNo: 1, pageSize: 15
    };
    get getGroupChatUpData(){ return this.GroupChatUpData }
    @Mutation
    SetGroupChatUpData(data: GroupChatHistoryListType): void {
        this.GroupChatUpData = data
    }

    ChatUpData: ChatListType|any = {
        // eslint-disable-next-line
        // @ts-ignore
        friendId: "", pageNo: 1, pageSize: 15
    };
    get getChatUpData(){ return this.ChatUpData }
    @Mutation
    SetChatUpData(data: ChatListType): void {
        this.ChatUpData = data
    }
}


export const ChatStore = getModule(Chat);
