import Storage from "@/util/Storage";


export function filterGroupChatList(list = []) {
    // state left right
    const userId = Storage.GetData_ && Storage.GetData_("userId");
    return list.map((item:any,index:number)=>{
        if ( item['userId'] == userId ){
            item['state'] = "right"
        }else{
            item['state'] = "left"
        }
        return item;
    })
}


export function filterChatList(list = [],friendName = "",friendImg = "") {
    //
    const userId = Storage.GetData_ && Storage.GetData_("userId");
    const userInfo = JSON.parse( Storage.GetData_?.("UserData") || "{}" );
    return list.map((item:any,index:number)=>{
        if ( item['receivedId'] != userId ){
            item['state'] = "right"
            item["headImg"] = userInfo.headImg
        }else{
            item['state'] = "left"
            item["userName"] = friendName || ''
            item["headImg"] = friendImg
        }
        return item;
    })
}
