import Storage from "@/util/Storage";
import {GroupChatHistoryList, ChatList} from "@/Api/Message";
import {ChatStore} from "@/views/Message/Chat/Store";

// 群聊天记录
export function getGroupChatList() {
    const userId = Storage.GetData_ && Storage.GetData_("userId");
    const token = Storage.GetData_ && Storage.GetData_("token");
    return  Promise.resolve(GroupChatHistoryList({ userId,token },ChatStore.getGroupChatUpData).then(res=>{
        return res && res.data
    }))
}

// 单对单记录
export function getChatList() {
    const userId = Storage.GetData_ && Storage.GetData_("userId");
    const token = Storage.GetData_ && Storage.GetData_("token");
    return  Promise.resolve(ChatList({ userId,token },ChatStore.getChatUpData).then(res=>{
        return res && res.data
    }))
}

